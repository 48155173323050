@if (!isIframe) {
<router-outlet />
}
<p-toast></p-toast>
<p-confirmDialog #cd [style]="{ width: '400px' }" [closable]="false">
  <ng-template pTemplate="footer">
    <div class="button-wrapper justify-content-end">
      <button class="btn-outline-secondary" (click)="cd.reject()">
        {{ "Shared.buttons.noBtn" | translate }}
      </button>
      <button class="button-primary" (click)="cd.accept()">
        {{ "Shared.buttons.yesBtn" | translate }}
      </button>
    </div>
  </ng-template>
</p-confirmDialog>
