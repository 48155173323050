import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AccountInfo,
  AuthenticationResult,
  AuthError,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { Store } from '@ngrx/store';
import { AuthorizationActions } from './store/actions';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

@Component({
  standalone: true,
  imports: [
    RouterOutlet,
    AsyncPipe,
    ToastModule,
    ConfirmDialogModule,
    TranslateModule,
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private readonly translate = inject(TranslateService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);
  isIframe = false;

  constructor(
    public msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalService.handleRedirectObservable().subscribe();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.SSO_SILENT_SUCCESS
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.checkAndSetActiveAccount(payload.account);
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount(null);
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        // Optional filtering of events
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          // Do something with the error
          localStorage.clear();
        }
      });
  }

  checkAndSetActiveAccount(account: AccountInfo | null) {
    let resolvedAccountInfo = account;
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!resolvedAccountInfo) {
      if (
        !activeAccount &&
        this.msalService.instance.getAllAccounts().length > 0
      ) {
        const accounts = this.msalService.instance.getAllAccounts();
        resolvedAccountInfo = accounts[0];
      }
    }
    if (resolvedAccountInfo) {
      this.store.dispatch(
        AuthorizationActions.setAccountInfo({
          accountInfo: resolvedAccountInfo,
        })
      );
      this.msalService.instance.setActiveAccount(resolvedAccountInfo);
    } else if (activeAccount) {
      this.store.dispatch(
        AuthorizationActions.setAccountInfo({ accountInfo: activeAccount })
      );
    }
  }
}
