import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import { environment } from '../../environments/environment';
import {
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
} from '@azure/msal-angular';

export function MSALInstanceFactory(): IPublicClientApplication {
  const authConfig = JSON.parse(localStorage.getItem('authConfig') || '{}');
  return new PublicClientApplication({
    auth: {
      clientId: authConfig.clientId ?? '',
      authority: environment.msalConfig.authority + authConfig.authority,
      redirectUri: '/',
      postLogoutRedirectUri: '/tenants',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      secureCookies: true,
      storeAuthStateInCookie: true,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        // TODO change logger level and way of logging in prod
        loggerCallback: (logLevel: LogLevel, message: string) => {},
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  const authConfig = JSON.parse(localStorage.getItem('authConfig') || '{}');
  protectedResourceMap.set(environment.msalConfig.uri, authConfig.scopes);
  protectedResourceMap.set(`${environment.baseUrl}/*`, authConfig.scopes);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const authConfig = JSON.parse(localStorage.getItem('authConfig') || '{}');
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: [...authConfig.scopes],
    },
    loginFailedRoute: '/tenants',
  };
}
