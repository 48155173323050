import { Routes } from '@angular/router';
import { authGuard, publicGuard } from './shared/guards';

const publicRoutes = () => import('./public/public.routing').then((m) => m.publicRoutes);
const privateRoutes = () => import('./private/routings/private.routing').then((m) => m.privateRoutes);

export const routes: Routes = [
  {
    path: 'tenants',
    pathMatch: 'full',
    loadChildren: publicRoutes,
    canActivate: [publicGuard],
  },
  {
    path: '',
    loadChildren: privateRoutes,
    canActivate: [authGuard],
  },
];
