import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class XGuardInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const authConfig = JSON.parse(localStorage.getItem('authConfig') || '{}');
    const modifiedRequest = request.clone({
      setHeaders: {
        'X-Guard': authConfig.guard ?? '',
      },
    });

    return next.handle(modifiedRequest);
  }
}
