import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MonitoringService } from './services/error-handler/monitoring.service';

@Injectable({
  providedIn: 'root',
})
export class AppErrorHandler extends ErrorHandler {
  public readonly router = inject(Router);

  constructor(private monitoringService: MonitoringService) {
    super();
  }

  override handleError(error: Error): void {
    const chunkFailedMessage = /Loading chunk \d + failed/;
    this.monitoringService.logException(error);
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }

    if (
      error.name === 'Error' &&
      error.message.includes('Uncaught (in promise): Error: NG04002')
    ) {
      this.router.navigate(['/non-found']);
    }
    super.handleError(error);
  }
}
