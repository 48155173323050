import { bootstrapApplication } from '@angular/platform-browser';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  HttpClient,
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { ConfirmationService, MessageService } from 'primeng/api';

import { AppComponent } from './app/app.component';
import { routes } from './app/app.routing';
import { BASE_URL } from './app/shared/api';
import { environment } from './environments/environment';

import { GlobalEffects } from './app/store/effects/global.effects';
import { ErrorHandler, importProvidersFrom } from '@angular/core';
import { AppErrorHandler } from './app/shared/app-error-handler';
import {
  TranslateLoader,
  TranslateModule,
  TranslatePipe,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService,
} from '@azure/msal-angular';
import {
  MSALGuardConfigFactory,
  MSALInstanceFactory,
  MSALInterceptorConfigFactory,
} from './app/auth/config';
import {
  XGuardInterceptor,
  XTenantInterceptor,
} from './app/private/interceptors';
import {
  ConfigurationCalculationModuleService,
  ConfigurationDropdownService,
  ConfigurationMeasureTypeService,
  CurrentUserService,
  HomeService,
  LoanService,
  ReportsService,
} from './app/private/services';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    DatePipe,
    MessageService,
    ConfirmationService,
    provideStore(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictActionTypeUniqueness: true,
          strictActionWithinNgZone: true,
        },
      }
    ),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      })
    ),
    provideEffects([GlobalEffects]),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
        defaultLanguage: 'en',
      })
    ),
    {
      provide: BASE_URL,
      useValue: environment.baseUrl,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XTenantInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: XGuardInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    TranslatePipe,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    LoanService,
    HomeService,
    ReportsService,
    ConfigurationMeasureTypeService,
    CurrentUserService,
    ConfigurationCalculationModuleService,
    ConfigurationDropdownService,
  ],
}).catch((err) => console.error(err));
